import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useLocation, Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateText } from '../../store/apps/translateText/translateTextSlice';
import useTranslateTexts from '../../Context/useTranslateTexts';
import { useTranslation } from 'react-i18next';

const BreadCrumbs = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const [translatedPaths, setTranslatedPaths] = useState({});
  const {t,i18n}=useTranslation()
  // const { lang } = useParams();

// Expression régulière pour correspondre et supprimer la partie de langue
const langRegex = new RegExp(`/${i18n.language}/`);
const cleanPath = location.pathname.replace(langRegex, '/');

// Diviser le chemin d'accès en segments après avoir supprimé la partie de langue
const segments = cleanPath.split('/').filter(p => p);

// Fonction pour capitaliser la première lettre d'une chaîne
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Remplacer les tirets par des espaces dans chaque segment et capitaliser la première lettre
const paths = segments.map(segment => 
  capitalizeFirstLetter(segment.replace(/-/g, ' '))
);
  // useEffect(() => {
  //   const translatePaths = async () => {
  //     const translationPromises = paths.map(async (path) => {
  //       const response = await dispatch(translateText({ text: path, targetLang:i18n.language })); // 'en' ou autre langue cible
  //       return { path, translation: response.payload.translatedText || path }; // Utiliser le texte original en cas d'échec
  //     });
  
  //     const translations = await Promise.all(translationPromises);
  
  //     const translationMap = {};
  //     translations.forEach((entry) => {
  //       translationMap[entry.path] = entry.translation;
  //     });
  
  //     setTranslatedPaths(translationMap);
  //   };
  
  //   translatePaths();
  // }, [location, dispatch,i18n.language]);
  const normalizeLanguageCode = (code) => {
    return code.split('-')[0]; // Prend la première partie avant le tiret
  };


  useEffect(() => {
    const translatePaths = async () => {
      const languageCode = normalizeLanguageCode(i18n.language);

      const translationPromises = paths.map(async (path) => {
        let textToTranslate = path;
  
        // Assurez-vous que la casse et les espaces sont cohérents pour les comparaisons
        const normalizedPath = path.toLowerCase().trim();
  
        // Utiliser des clés de traduction directement pour certains chemins spécifiques
        if (normalizedPath === 'ip management') {
          textToTranslate = t('ipm'); // Clé de traduction pour 'Ip management'
        } else if (normalizedPath === 'sql xss injection') {
          textToTranslate = t('insql'); // Clé de traduction pour 'Sql xss injection'µ
          
        } else {
          // Pour les autres chemins, utilisez translateText
          try {
            const response = await dispatch(translateText({ text: textToTranslate, targetLang: languageCode.toUpperCase()  }));
            textToTranslate = response?.payload?.translatedText || path;
          } catch (error) {
            console.error('Error translating path:', path, error);
            // En cas d'erreur, conservez le texte original
            textToTranslate = path;
          }
        }
  
        return { path, translation: textToTranslate };
      });
  
      const translations = await Promise.all(translationPromises);
  
      const translationMap = {};
      translations.forEach((entry) => {
        translationMap[entry.path] = entry.translation;
      });
  
      setTranslatedPaths(translationMap);
    };
  
    translatePaths();
  }, [location, dispatch,i18n.language]);
  
 
  
  const texts={
    accueil:'HomePage'
  }
  // const transformedFirstUrl = formatCamelCaseAndDashesAsTitle(firstUrl);
  // const transformedSecondUrl = formatCamelCaseAndDashesAsTitle(secondUrl);
  const translatedTexts = useTranslateTexts(texts);
  function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  return (
    <>
   
        <Breadcrumb>
      {/* Lien vers l'accueil toujours présent */}
      <BreadcrumbItem to={`/${i18n.language}/Tableau-de-bord`} tag={Link} className="text-decoration-none link-primary fw-normal">
        {decodeHtml(translatedTexts.accueil ?? '')}
      </BreadcrumbItem>
      
      {/* Générer dynamiquement les autres éléments du breadcrumb */}
      {paths.map((path, index) => (
        <BreadcrumbItem key={index} active>
          {decodeHtml(translatedPaths[path] ?? '')}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
       
    </>
  );
};

export default BreadCrumbs;

import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { socket } from '../../../socket';
import useTranslateTexts from '../../../Context/useTranslateTexts';

export default function OnlineUsers({usersCount, onlineUsers}) {
    const texts = {
        onlineUsers: "Utilisateurs en ligne"
      }
      const translatedTexts = useTranslateTexts(texts);
    
  return (
    <Card className="p-3" style={{width: "fit-content"}}>
        <h4 className='m-0 fw-bold text-black'>{translatedTexts.onlineUsers}: {usersCount}</h4>
        <div style={{maxHeight: "100px", overflow: "auto"}}>
            <div className='px-4' style={{display: "grid", gridTemplateColumns: "auto auto auto", gap: "4px 20px", alignItems: "center"}}>
            {
                Object.keys(onlineUsers).map((key, index) => {
                    return (
                        <UserItem key={onlineUsers[key]._id} itemKey={key} onlineUsers={onlineUsers}  />
                        )
                    }
                )
            }
            </div>
       </div>
    </Card>
  )
}

OnlineUsers.propTypes = {
    usersCount: PropTypes.number,
    onlineUsers: PropTypes.object
}

function UserItem({itemKey, onlineUsers}) {
    const [input, setInput] = useState({
        notification: ""
    })

    function sendNotification(e){
        e.preventDefault()
        socket.emit("notification", {userId: onlineUsers[itemKey]._id, message: input.notification})
        setInput(prev => ({ notification: "" }))
    }
    
    return (
        <>
            <div className=''>
                <p className='m-0 fw-bold fs-5' style={{color: "#444"}}>{onlineUsers[itemKey].username}</p>
            </div>
            <div className=''>
                <p className='m-0 fw-normal fs-5'>{onlineUsers[itemKey].email}</p>
            </div>
            <form onSubmit={sendNotification} className='d-flex gap-1'>
                <button type='submit' className='btn btn-success p-1'>Notify</button>
                <input className='form-control' type='text' placeholder='Aaa' value={input.notification} onChange={e => setInput(prev => ({...prev, notification: e.target.value}))} />
            </form>
        </>
    )
}

UserItem.propTypes = {
    itemKey: PropTypes.string,
    onlineUsers: PropTypes.object
}

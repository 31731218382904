import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginUser,
  profileUser,
  resetAuthState,
  updateUserPassword,
} from '../../../store/apps/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { Axios } from '../../../config';
import QRCode from 'react-qr-code';
import PropTypes from "prop-types"
import BackupCodesModal from './BackupCodesModal';

const TOTP = () => {
  const { users, isSuccessPass, isErrorPass } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showAlertErrorConnexion, setShowAlertErrorConnexion] = useState(null);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(null);
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [showSecret, setShowSecret] = useState(false)
  const [loading, setLoading] = useState({
    generateUri: false,
    verifyCode: false,
    disableTotp: false
  })
  const [showBackupCodesModal, setShowBackupCodesModal] = useState(false)
  const [backupCodes, setBackupCodes] = useState(null)

  useEffect(() => {
    
    if (isSuccessPass) {
      setSuccessAlert(translatedTexts.alertS);
      setErrorAlert(null);

      setTimeout(() => {
        setSuccessAlert(null);
      }, 3000);
    } else if (isErrorPass) {
      setErrorAlert(translatedTexts.alertE );
      setTimeout(() => {
        setErrorAlert(null);
      }, 3000);
    }
  }, [isErrorPass, isSuccessPass]);
  const texts = {
    twofa: 'Authentification à deux facteurs: TOTP',
    twofaDescription: "L'authentification à 2 facteurs ajoute une couche de sécurité supplémentaire à votre compte.",
    enabled: 'Activé',
    notEnabled: "Désactivé",
    enable: "Activer",
    disable: "Désactiver",
    downloadAuthenticator: "Téléchargez une application d'authentification comme Microsoft Authenticator, Authy, etc. Et scannez ce code QR.",
    enterCode: "Entrez le code généré par l'application",
    cannotEnterCode: "Vous ne pouvez pas scannez le code QR?",
    verify: "Vérifier",
    secretCode: "Entrer ce code secret dans l'application d'authentification:",
    security: "Sécurité",
    saved: "J'ai sauvegardé mes codes de secours, fermer",
    yourBackupCodes: "Vos codes de secours:",
    saveBackupCodes: "Faites une capture d'écran ou enregistrez ces codes, vous ne pourrez plus jamais les revoir.",
    downloadBackupCodes: "Télécharger les codes de secours",
        };
  const translatedTexts = useTranslateTexts(texts);

  async function generateTotpUri(){
    if (loading.generateUri) return;

    setLoading(prev => ({...prev, generateUri: true}))
    try{
      const res = await Axios.get('/user/generateTotpUri');
      console.log(res.data)
      setOTP(res.data)
    } catch(err){
      console.log(err)
    }
    setLoading(prev => ({...prev, generateUri: false}))
  }
  
  async function verifyTotp(e){
    e.preventDefault()

    if (loading.verifyCode) return;
    
    setLoading(prev => ({...prev, verifyCode: true}))
    try{
      const res = await Axios.post('/user/verifyTotp', {secret: OTP.secret, token:code });
      if (users?.nbBackupCodes === 0){
        const backupCodesRes = await Axios.post('/user/generateBackupCodes');
        setBackupCodes(backupCodesRes.data)
        setShowBackupCodesModal(true)
      }
      setOTP(null)
      setCode(null)
      dispatch(profileUser())
    } catch(err){
      setError("Code invalide")
      console.log(err)
    }
    setLoading(prev => ({...prev, verifyCode: false}))
  }

  async function disableTotp(){
    if (loading.disableTotp) return;
    
    setLoading(prev => ({...prev, disableTotp: true}))
    try{
      const res = await Axios.post('/user/disableTotp');
      dispatch(profileUser())
    } catch(err){
      console.log(err)
    }
    setLoading(prev => ({...prev, disableTotp: false}))
  }

  return (
    <div>
      
      <div className="card p-3 rounded" style={{maxWidth:"700px"}}>
        <div className="">
          <div className='d-flex align-items-center justify-content-between gap-2 mb-2' style={{flexWrap: "wrap"}}>
            <h3 className='m-0 fw-bold text-black'>{translatedTexts.twofa}</h3>
          </div>
          <p className='fw-normal'>{translatedTexts.twofaDescription}</p>
          <div className='d-flex flex-column gap-2'>
            {
              users?.totpEnabled ?
              <div className="d-flex gap-2 align-items-center">
                <div className="toggle-switch">
                  <input className="toggle-input" id="toggle-totp" name="toggle-totp" type="checkbox" checked onClick={disableTotp} />
                  <label className="toggle-label" htmlFor="toggle-totp"></label>
                </div>
                {
                  loading.disableTotp ?
                  <div className='loader-sm loader-black' ></div>
                  : ""
                }
              </div>
              : !OTP ?
              <div className='d-flex gap-2 align-items-center'>
                <div className="toggle-switch">
                  <input className="toggle-input" id="toggle-totp" name="toggle-totp" type="checkbox" checked={false} onClick={generateTotpUri} />
                  <label className="toggle-label" htmlFor="toggle-totp"></label>
                </div>
                {
                  loading.generateUri ?
                  <div className='loader-sm loader-black' ></div>
                  : ""
                }
              </div>
              :
              ""
            }
            {
              OTP && 
              <div className='d-flex flex-column gap-2'>
                  <p className='m-0 fw-normal'>{translatedTexts.downloadAuthenticator}</p>
                  <form onSubmit={verifyTotp} style={{width: "fit-content"}}>
                    <div className='p-2 border border-secondary rounded' style={{width: "100%", maxWidth: "250px"}}>
                      <div style={{ height: "auto", width: "100%" }}>
                        <QRCode value={OTP?.otpuri || ""} style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
                      </div>
                    </div>
                    {
                      !showSecret ?
                      <button type="button" className='m-0 mt-2 p-1 btn btn-info' style={{ fontSize: "10px" }} onClick={()=>{setShowSecret(true)}} >{translatedTexts?.cannotEnterCode}</button>
                      : ""
                    }
                    {
                      showSecret ?
                      <>
                      <p className='m-0 mt-2 fw-normal'>{translatedTexts.secretCode}</p>
                      <p className='alert alert-info p-1 fw-normal' style={{width: "fit-content"}}>{OTP?.secret}</p>
                      </>
                      : ""
                    }
                    <p className='m-0 mt-2 fw-normal'>{translatedTexts.enterCode}</p>
                    <Input type="text" name="otp" placeholder="XXXXXX" value={code} onChange={(e)=>{setCode(e.target.value)}} />
                    {
                      error ?
                      <p className='m-0 text-danger'>{error}</p>
                      : ""
                    }
                    <div className='position-relative mt-2'>
                      <Button type="submit" className="btn-success w-100 " >{translatedTexts.verify}</Button>
                      {
                        loading.verifyCode ?
                        <div className="position-absolute" style={{top: "50%", right: "5px", transform: "translateY(-50%)"}}>
                          <div className='loader-sm' ></div>
                        </div>
                        : ""
                      }
                    </div>
                  </form>
                </div>
            }
          </div>
        </div>
    </div>

    <BackupCodesModal show={showBackupCodesModal} toggle={()=>{setShowBackupCodesModal(prev => !prev)}} translatedTexts={translatedTexts} backupCodes={backupCodes} />
  </div>
  );
};

export default TOTP;
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTranslateTexts from '../../Context/useTranslateTexts';
import BackupCodes from './security/BackupCodes';
import ConnectionMethods from './security/ConnectionMethods';
import PhoneOTP from './security/PhoneOTP';
import RecoveryEmail from './security/RecoveryEmail';
import TOTP from './security/TOTP';
// import PhoneOTPFirebase from './security/PhoneOTPFirebase';

const Security = () => {
  const { users, isSuccessPass, isErrorPass } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showAlertErrorConnexion, setShowAlertErrorConnexion] = useState(null);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(null);
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [showSecret, setShowSecret] = useState(false)

  useEffect(() => {
    
    if (isSuccessPass) {
      setSuccessAlert(translatedTexts.alertS);
      setErrorAlert(null);

      setTimeout(() => {
        setSuccessAlert(null);
      }, 3000);
    } else if (isErrorPass) {
      setErrorAlert(translatedTexts.alertE );
      setTimeout(() => {
        setErrorAlert(null);
      }, 3000);
    }
  }, [isErrorPass, isSuccessPass]);
  const texts = {
    enabled: 'Activé',
    notEnabled: "Désactivé",
    enable: "Activer",
    disable: "Désactiver",
    verify: "Vérifier",
    security: "Sécurité",
        };
  const translatedTexts = useTranslateTexts(texts);

  
  return (
    <div>
      <h2 className="text-uppercase">{translatedTexts?.security}</h2>
      <TOTP />
      {/* <PhoneOTP /> */}
      {/* <PhoneOTPFirebase /> */}
      <BackupCodes />
      <RecoveryEmail />
      {/* <RecoveryPhone /> */}
      <ConnectionMethods />
  </div>
  );
};

export default Security;

export function formatRelativeTime(date){
    const now = Date.now()
  
    let timestamp = null
    try{
      timestamp = new Date(date).getTime()
    } catch (err){
      return null
    }
  
    let diff = timestamp - now
    // const seconds = Math.floor(diff / 1000)
    // const minutes = Math.floor(seconds / 60)
    // const hours = Math.floor(minutes / 60)
    // const days = Math.floor(hours / 24)
  
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const daysStr = days.toString().padStart(2, "0")
    diff -= (days * 1000 * 60 * 60 * 24)
    const hours = Math.floor(diff / (1000 * 60 * 60))
    const hoursStr = hours.toString().padStart(2, "0")
    diff -= (hours * 1000 * 60 * 60)
    const minutes = Math.floor(diff / (1000 * 60))
    const minutesStr = minutes.toString().padStart(2, "0")
    diff -= (minutes * 1000 * 60)
    const seconds = Math.floor(diff / 1000)
    const secondsStr = seconds.toString().padStart(2, "0")
  
    if (days) {
      return `${daysStr}d ${hoursStr}h`
    }
  
    if (hours) {
      return `${hoursStr}h ${minutesStr}m`
    }
  
    return `${minutesStr}m ${secondsStr}s`
  
  }
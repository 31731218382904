import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { Axios } from '../../../config';
import {
  profileUser
} from '../../../store/apps/user/userSlice';


const ConnectionMethods = () => {
  const { users, isSuccessPass, isErrorPass } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showAlertErrorConnexion, setShowAlertErrorConnexion] = useState(null);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(null);
  const [error, setError] = useState("")
  const [input, setInput] = useState({
    providers: {
      github: users?.providers?.github,
      google: users?.providers?.google,
      linkedin: users?.providers?.linkedin,
      credentials: users?.providers?.credentials
    }
  })
  const [loading, setLoading] = useState({
    updateProviders: false
  })

  useEffect(() => {
    
    if (isSuccessPass) {
      setSuccessAlert(translatedTexts.alertS);
      setErrorAlert(null);

      setTimeout(() => {
        setSuccessAlert(null);
      }, 3000);
    } else if (isErrorPass) {
      setErrorAlert(translatedTexts.alertE );
      setTimeout(() => {
        setErrorAlert(null);
      }, 3000);
    }
  }, [isErrorPass, isSuccessPass]);
  const texts = {
    connectionMethods: 'Méthodes de connexion',
    security: "Sécurité",
    noBackupCodes: "Aucun code de secours disponible",
    generateBackupCodes: "Générer de nouveaux codes de secours",
    XBackupCodesAvailable: "codes de secours disponibles",
    saveBackupCodes: "Faites une capture d'écran ou enregistrez ces codes, vous ne pourrez plus jamais les revoir.",
    downloadBackupCodes: "Télécharger les codes de secours",
    save: "Enregistrer",
    blockedMethods: "Vous ne pouvez pas se connecter avec les méthodes de connexion désactivées",
    notLinked: "Non lié"
        };
  const translatedTexts = useTranslateTexts(texts);

  async function updateProviders(){
    if (loading.updateProviders) return;
    
    setLoading(prev => ({...prev, updateProviders: true}))
    try{
      const res = await Axios.put('/user/updateProviders', {providers: input.providers});
      dispatch(profileUser())
    } catch(err){
      console.log(err)
    }
    setLoading(prev => ({...prev, updateProviders: false}))
  }

  const inputNotModified = input?.providers?.google === users?.providers?.google && input?.providers?.github === users?.providers?.github && input?.providers?.linkedin === users?.providers?.linkedin && input?.providers?.credentials === users?.providers?.credentials
  
  return (
    <div>
      
      <div className="card p-3 rounded" style={{maxWidth:"700px"}}>
        <div className="">
          <h3 className='m-0 mb-2 fw-bold text-black'>{translatedTexts.connectionMethods}</h3>
          {
            Object.keys(users?.providers)?.map((provider) => (
              <div key={provider} className="d-flex align-items-center gap-2 form-control mb-2">
                <div className='' style={{width: "20px"}}>{ICONS[provider]}</div>
                <p className='flex-grow-1 m-0 fw-bolder text-black text-capitalize'>{provider}</p>
                {
                  input?.providers[provider] === "unlinked" ?
                  <p className='m-0 p-1 rounded fw-normal text-black' style={{fontSize: "11px", background: "#eee"}}>{translatedTexts.notLinked}</p>
                  : ""
                }
                <div className="toggle-switch">
                  <input className="toggle-input" id={`toggle-${provider}`} type="checkbox" checked={input?.providers[provider] !== "blocked"}
                  onChange={() => {
                    // The user can't disable all the login methods at the same time, at least one must be enabled
                    setInput(prev => {
                      const newInput = ({...prev, providers: { ...prev.providers, [provider]: prev?.providers[provider] !== "blocked" ? "blocked" : "yes" }});
                      if (Object.values(newInput?.providers).reduce((acc, val) => acc + Number(val === "blocked"), 0) < 4) return newInput;
                      else return prev;
                    }) 
                }} />
                  <label className="toggle-label" htmlFor={`toggle-${provider}`}></label>
                </div>
              </div>
            ))
          }
          {
            Object.keys(input?.providers)?.some(provider => input?.providers[provider] === "blocked") ?
            <div className='alert alert-danger fw-normal p-2'>{translatedTexts?.blockedMethods}</div>
            : ""
          }
          {
            !inputNotModified ? 
            <div className='d-flex gap-2 justify-content-end align-items-center'>
              {
                loading.updateProviders ?
                <div className='loader-sm loader-black' ></div>
                : ""
              }
              <button type='button' onClick={updateProviders} className="btn btn-success" style={{fontSize: "13px", width: "fit-content"}}>{translatedTexts.save}</button>
            </div> : ""
          }
        </div>
      </div>
    </div>
  );
};

export default ConnectionMethods;

const ICONS = {
  github: <GithubIcon />,
  google: <GoogleIcon />,
  linkedin: <LinkedinIcon />,
  credentials: <LockPasswordIcon />
}

function GoogleIcon(){
  return <svg
          xmlns="http://www.w3.org/2000/svg"
          height={18}
          width={18}
          viewBox="0 0 186.69 190.5"
        >
          <path
            fill="#4285f4"
            d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
            transform="translate(1184.583 765.171)"
          ></path>
          <path
            fill="#34a853"
            d="M-1142.714-651.791l-6.972 5.337-24.679 19.223c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
            transform="translate(1184.583 765.171)"
          ></path>
          <path
            fill="#fbbc05"
            d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
            transform="translate(1184.583 765.171)"
          ></path>
          <path
            fill="#ea4335"
            d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
            transform="translate(1184.583 765.171)"
          ></path>
        </svg>
}

function GithubIcon(){
  return <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="black" className="mr-2" viewBox="0 0 1792 1792">
  <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z" />
</svg>;
}

function LinkedinIcon(){
  return <svg xmlns="http://www.w3.org/2000/svg" fill='#0a66c2' width={20} height={20} viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>;
}

function LockPasswordIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color="#000000" fill="none" {...props}>
    <path d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12105 13.2453 4 14.3624 4 15.5C4 16.6376 4.12105 17.7547 4.26781 18.8447Z" stroke="currentColor" strokeWidth="1.5" />
    <path d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 15.49V15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 15.49V15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15.49V15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}
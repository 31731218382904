import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../../../config'; 

export const translateText = createAsyncThunk('translate/translateText', async ({ text, targetLang }, { rejectWithValue }) => {
  // return {translatedText: text}
  try {
    let res = await Axios.post(`/translate/trans`, { text, targetLang });
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
const  initialState= {
  text: '',
  translatedText: {},
  targetLang: '',
  isFetchingTrans: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
}
export const translateTextSlice = createSlice({
  name: 'translateText',
  initialState,
  reducers: {
    clearTranslateData:  () => initialState},
  extraReducers: (builder) => {
    builder
      .addCase(translateText.pending, (state) => {
        state.isFetchingTrans = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(translateText.fulfilled, (state, { payload }) => {
        state.isFetchingTrans = false;
       state.translatedText = payload.translatedText;
        state.isSuccess = true;
      })
      .addCase(translateText.rejected, (state, action) => {
        state.isFetchingTrans = false;
        state.isError = true;
        state.errorMessage = action.payload || 'Failed to translate text';
      });
  }
});

export const { clearTranslateData } = translateTextSlice.actions;
export default translateTextSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { Axios } from '../../../config';
import {
  profileUser
} from '../../../store/apps/user/userSlice';
import BackupCodesModal from './BackupCodesModal';


const BackupCodes = () => {
  const { users, isSuccessPass, isErrorPass } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showAlertErrorConnexion, setShowAlertErrorConnexion] = useState(null);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(null);
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [showSecret, setShowSecret] = useState(false)
  const [backupCodes, setBackupCodes] = useState(null)
  const [showBackupCodesModal, setShowBackupCodesModal] = useState(false)
  const [loading, setLoading] = useState({
    generateBackupCodes: false
  })

  useEffect(() => {
    
    if (isSuccessPass) {
      setSuccessAlert(translatedTexts.alertS);
      setErrorAlert(null);

      setTimeout(() => {
        setSuccessAlert(null);
      }, 3000);
    } else if (isErrorPass) {
      setErrorAlert(translatedTexts.alertE );
      setTimeout(() => {
        setErrorAlert(null);
      }, 3000);
    }
  }, [isErrorPass, isSuccessPass]);
  const texts = {
    backupCodes: 'Codes de secours',
    backupCodesDescription: "Les codes de sauvegarde peuvent être utilisés pour accéder à votre compte si vous perdez l'accès à vos autres méthodes d'authentification.",
    security: "Sécurité",
    noBackupCodes: "Aucun code de secours disponible",
    generateBackupCodes: "Générer de nouveaux codes de secours",
    XBackupCodesAvailable: "codes de secours disponibles",
    saveBackupCodes: "Faites une capture d'écran ou enregistrez ces codes, vous ne pourrez plus jamais les revoir.",
    downloadBackupCodes: "Télécharger les codes de secours",
    saved: "J'ai sauvegardé mes codes de secours, fermer",
    yourBackupCodes: "Vos codes de secours:"
        };
  const translatedTexts = useTranslateTexts(texts);

  async function generateBackupCodes(){
    if (loading.generateBackupCodes) return;

    setLoading(prev => ({...prev, generateBackupCodes: true}))
    try{
      const res = await Axios.post('/user/generateBackupCodes');
      setBackupCodes(res.data)
      setShowBackupCodesModal(true)
      dispatch(profileUser())
    } catch(err){
      console.log(err)
    }
    setLoading(prev => ({...prev, generateBackupCodes: false}))
  }


  return (
    <div>
      
      <div className="card p-3 rounded" style={{maxWidth:"700px"}}>
        <div className="">
          <h3 className='m-0 mb-2 fw-bold text-black'>{translatedTexts.backupCodes}</h3>
          <p className='fw-normal'>{translatedTexts.backupCodesDescription}</p>
          <p className='px-2 py-1 border rounded fw-normal ' style={{width: "fit-content"}}>{users?.nbBackupCodes ? users?.nbBackupCodes + " " + translatedTexts?.XBackupCodesAvailable : translatedTexts?.noBackupCodes}</p>
          <div className='d-flex gap-2 align-items-center flex-wrap' style={{width: "fit-content"}}>
            <button onClick={generateBackupCodes} type='button' className='d-flex align-items-center gap-2 btn border hover:bg-gray text-black' style={{background: "#eee"}}>
              <p className='m-0'>
              {translatedTexts?.generateBackupCodes} 
              </p>
              <ResetPasswordIcon />
            </button>
            {
              loading.generateBackupCodes ?
              <div className='mx-auto loader-sm loader-black'></div>
              : ""
            }
          </div>
        </div>
      </div>

      <BackupCodesModal show={showBackupCodesModal} toggle={()=>{setShowBackupCodesModal(prev => !prev)}} translatedTexts={translatedTexts} backupCodes={backupCodes} />
    </div>
  );
};

export default BackupCodes;

const ResetPasswordIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color="#000000" fill="none" {...props}>
    <path d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75334 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75334 2.5 12 2.5C15.8956 2.5 19.2436 4.84478 20.7095 8.2M21.5 5.5L21.025 8.675L18 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 11V9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5V11M11.25 16.5H12.75C13.9228 16.5 14.5092 16.5 14.9131 16.19C15.0171 16.1102 15.1102 16.0171 15.19 15.9131C15.5 15.5092 15.5 14.9228 15.5 13.75C15.5 12.5772 15.5 11.9908 15.19 11.5869C15.1102 11.4829 15.0171 11.3898 14.9131 11.31C14.5092 11 13.9228 11 12.75 11H11.25C10.0772 11 9.49082 11 9.08686 11.31C8.98286 11.3898 8.88977 11.4829 8.80997 11.5869C8.5 11.9908 8.5 12.5772 8.5 13.75C8.5 14.9228 8.5 15.5092 8.80997 15.9131C8.88977 16.0171 8.98286 16.1102 9.08686 16.19C9.49082 16.5 10.0772 16.5 11.25 16.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { translateText } from '../store/apps/translateText/translateTextSlice';
import { useTranslation } from 'react-i18next';

const normalizeLanguageCode = (code) => {
  return code.split('-')[0]; // Prend la première partie avant le tiret
};

const useTranslateTexts = (texts) => {
  const {t,i18n}=useTranslation()
  const dispatch = useDispatch();
  const [translatedTexts, setTranslatedTexts] = useState(texts);

  useEffect(() => {
    const translateTexts = async () => {
        try {
          const languageCode = normalizeLanguageCode(i18n.language);

          // Créer un tableau de promesses pour toutes les traductions
          const translationPromises = Object.keys(texts).map(key => 
            dispatch(translateText({ text: texts[key], targetLang: languageCode.toUpperCase() }))
              .then(response => ({ key, translatedText: response.payload.translatedText }))
              .catch(() => ({ key, translatedText: texts[key] })) // Gestion des erreurs pour chaque traduction
          );
      
          // Attendre que toutes les promesses soient résolues
          const results = await Promise.all(translationPromises);
      
          // Construire l'objet newTranslations à partir des résultats
          const newTranslations = results.reduce((acc, { key, translatedText }) => {
            acc[key] = translatedText;
            return acc;
          }, {});
      
          setTranslatedTexts(newTranslations);
        } catch (error) {
          console.error('Error during translation:', error);
          // Gérer l'erreur ici si nécessaire
        }
      };
      
    translateTexts();
  }, [i18n.language, dispatch]); // Ajouter texts aux dépendances si nécessaire

  return translatedTexts;
};

 


export default useTranslateTexts;

import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

export default function LoaderSimple() {
  return (
    <div>      <Spinner color="primary" />
    </div>
  )
}

import React from 'react';
import { ReactComponent as LogoBlueSecurasText } from '../../assets/images/logos/blue-logo-securas-text.svg';
import { ReactComponent as LogoBlueSecurasIcon } from '../../assets/images/logos/blue-logo-securas-icon.svg';

const SecurasLogo = () => {
    return (
        <div className=" d-flex align-items-center justify-content-center">
     {/* {isDarkMode !== false ? ( */}
        <>
          <LogoBlueSecurasIcon />
          {/* <LogoBlueSecurasText /> */}
        </>
      {/* ) : (
        <>
          <LogoDarkIcon />
          <LogoDarkText />
        </>
      )} */}
    </div>
    );
}

export default SecurasLogo;

import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Label
} from 'reactstrap';
import * as Yup from 'yup';
import { Axios } from '../../../config';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { formatRelativeTime } from './formatDate';
import { profileUser } from '../../../store/apps/user/userSlice';


const TOTP = ({ setMethod }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()

  const [showAlertError, setShowAlertError] = useState(null);
  const formik = useFormik({
    initialValues: {
      otp: ''
    },
  });
  const [blockedUntil, setBlockedUntil] = useState(null)

  
  
  const currentLanguage = i18n.language
  const texts = {
    verifyOtp: 'Vérifier',
    invalidOTP: "Code invalide",
    sixCharacters: "Le code doit contenir 6 caractères",
    enterOtp: 'Veuillez entrer le code OTP (via l\'application d\'authentification)',
    totp: "TOTP",
    otherMethod: "Se connecter avec une autre méthode",
    blocked: "Trop de tentatives de connexion, réessayer dans $1",
    invalidAttempt: "Code invalide, il vous reste $1 tentatives",
}

const translatedTexts = useTranslateTexts(texts);
const navigate = useNavigate();


function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email(decodeHtml(translatedTexts.emI))
//     .required(decodeHtml(translatedTexts.emReq))
//     .transform((value) => value.toLowerCase()), // Transformer pour mettre en minuscules
//   password: Yup.string()
//     .min(6, decodeHtml(translatedTexts.pass))
//     .required(decodeHtml(translatedTexts.passReq)),
// })
const validationSchema = Yup.object().shape({
  otp: Yup.string()
  .required(translatedTexts?.sixCharacters)
.min(6, translatedTexts?.sixCharacters)
.max(6, translatedTexts?.sixCharacters)
});

const handleSubmit = async (values, { setSubmitting }, users) => {
  setSubmitting(true)
  try{
    const res = await Axios.post('/user/loginWithTotp', {otp: values.otp})

    await dispatch(profileUser())
    
    navigate(`/${currentLanguage}/dashboard`);
  } catch (err){
    if (err?.response?.data?.msg === "Invalid OTP"){
      setShowAlertError(decodeHtml(translatedTexts?.invalidAttempt?.replace("$1", err?.response?.data?.remainingAttempts)))
    } else if (err?.response?.data?.msg === "too many requests"){
      setBlockedUntil(err?.response?.data?.blockedUntil)
      // setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
    } else {
      setShowAlertError(err?.response?.data?.msg);
    }
  }
  setSubmitting(false)
}

useEffect(()=>{
  if (blockedUntil){
    const formattedTime = formatRelativeTime(blockedUntil)
    setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
    const interval = setInterval(()=>{
      const formattedTime = formatRelativeTime(blockedUntil)
      if (new Date(blockedUntil) < new Date()){
        setBlockedUntil(null)
        setShowAlertError(null)
        clearInterval(interval)
      } else {
        setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
      }
    }
    ,1000)
    return () => clearInterval(interval)
  }
},[blockedUntil])

  return (
   
      <div>
        <Formik
                  initialValues={formik.initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
    {({ errors, touched, values, setFieldValue, isSubmitting }) => (
        <Form>
          <h3>{translatedTexts?.totp}:</h3>
            <FormGroup>
            <Label htmlFor="otp">   {decodeHtml(translatedTexts?.enterOtp)}</Label>

            <Field
                name="otp"
                id="otp"
                type="text"
                placeholder="XXXXXX"
                value={values.otp}
                className="form-control"
                />
            <ErrorMessage name="otp" component="div" className="" style={{color: "tomato"}} />
            </FormGroup>
            <div className="position-relative">
              <Button
                  type="submit"
                  className="btn-block"
                  color="primary"
                  size="lg"
                  block
              >
                  {decodeHtml(translatedTexts?.verifyOtp)}
              </Button>
              {
                isSubmitting ?
                <div className="position-absolute" style={{top: "50%", right: "20px", transform: "translateY(-50%)"}}>
                  <div className='loader-sm' ></div>
                </div>
                : ""
              }
            </div>
    </Form>
    )}
        </Formik>
        <button type='button' onClick={()=>{ setMethod("select") }} style={{all:"unset"}} className='hover:underline' >{translatedTexts.otherMethod}</button>
        {showAlertError && <p className="m-0 mt-3 p-2 alert alert-danger fw-normal" style={{fontSize: "12px"}}>{showAlertError}</p>}
      </div>
  );
};

export default TOTP;

TOTP.propTypes = {
  setMethod: PropTypes.func
}
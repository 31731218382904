import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Axios } from '../../config';
import useTranslateTexts from '../../Context/useTranslateTexts';

// Schema de validation
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Adresse email invalide')
        .required('Le champ email est obligatoire'),
});

export default function ResetPassword({ setMethod, setResetMethods }) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()
    const [showAlertError, setShowAlertError] = useState(null);
    const [showAlertSuccess, setShowAlertSuccess] = useState(null);
    const normalizeLanguageCode = (code) => {
        return code.split('-')[0]; // Prend la première partie avant le tiret
    };
    const languageCode = normalizeLanguageCode(i18n.language);
    const translatedTexts = useTranslateTexts({
        frg: "Password Reset",
        btn: 'Login',
        em: 'Email Verification',
        env: ' Envoyer le lien de réinitialisation',
        emm: 'E-mail',
        plc: 'Enter your email',
        prag:'Enter the e-mail address you used when you joined and we’ll send you instructions to reset your password.' });
  
    // const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //     setSubmitting(true);
    //     let trans; // Declare trans outside try-catch to make it accessible in the catch block

    //     try {
    //         const response = await dispatch(requestReset({ email: values.email }))
    //         console.log(response)
    //         trans = await dispatch(translateText({ text: response?.payload?.msg, targetLang: languageCode }));

    //         if (response?.payload?.msg === 'Aucun utilisateur trouvé avec cet email. ') {
    //             setShowAlertError(trans.payload.translatedText);
    //             setShowAlertSuccess(null)
    //         }
    //         else {
    //             setShowAlertSuccess(trans.payload.translatedText);

    //         }
    //         resetForm();

    //     } catch (error) {
    //         setShowAlertError('Une erreur est survenue lors de l\'enregistrement de l\'email.');
    //     } finally {
    //         setSubmitting(false);
    //         setTimeout(() => {
    //             setShowAlertSuccess(null);
    //             setShowAlertError(null);
    //         }, 3000);
    //     }
    // }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
            const res = await Axios.get("/user/getResetMethods", { params: { email: values.email } });
            
            setResetMethods(res.data)
            console.log(res.data);
        } catch (err) {
            if (err?.response?.data?.msg === "User not found"){
                setShowAlertError("User not found")
              } else {
                setShowAlertError(err?.response?.data?.msg);
              }
        }

        setSubmitting(false);
        
    }
    
    return (
        <div>
            {showAlertSuccess && (
                <div className="alert alert-success">{showAlertSuccess}</div>
            )}
            {showAlertError && (
                <div className="alert alert-danger">{showAlertError}</div>
            )}
            <h1>{translatedTexts.frg}</h1>
            <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <FormikForm>
                                                <p className='text-center'>{translatedTexts?.prag}</p>

                        <FormGroup>
                        <Label for="email">{translatedTexts.emm}</Label>
                        <Field
                                name="email"
                                as={Input} // Utilisation de l'élément Input de Reactstrap
                                type="email"
                                placeholder={translatedTexts?.plc}
                                className="form-control"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </FormGroup>

                        <Button type="submit" color="primary" disabled={isSubmitting}>
                        {translatedTexts.env}
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}

ResetPassword.propTypes = {
    setMethod: PropTypes.func,
    setResetMethods: PropTypes.func,
}
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function BackupCodesModal({show, toggle, translatedTexts, backupCodes}) {
    const {users} = useSelector((state) => state.userReducer);
  
    return <Modal isOpen={show} toggle={toggle} centered backdrop="static" keyboard={false} >
      <ModalBody className="d-flex justify-content-center align-items-center text-center flex-column">
        <p className='fs-4 text-black fw-bold'>{translatedTexts.yourBackupCodes}</p>
        {
          backupCodes ? 
          <>
              <div className='d-grid gap-2' style={{gridTemplateColumns: "repeat(2, minmax(150px, 1fr)"}}>
                {
                  backupCodes.map((code, index) => (
                    <p key={code} className='p-1 m-0 rounded text-center text-black fw-normal' style={{background: "#eee"}}>
                      {`${code.slice(0, 4)} ${code.slice(4, 8)}`}
                    </p>
                  ))
                }
              </div>
              <p className='mt-3 fw-normal'>{translatedTexts.saveBackupCodes}</p>
              <button onClick={()=> downloadBackupCodes(users.email, backupCodes)} type='button' className='d-flex align-items-center gap-2 btn border hover:bg-gray text-black' style={{background: "#eee"}}>
              <p className='m-0'>
              {translatedTexts?.downloadBackupCodes} 
              </p>
              <DownloadIcon />
            </button>
                </>
                : ""
          }
      </ModalBody>
      <ModalFooter>
            <Button className="mx-auto" color="success" onClick={toggle}>
                {translatedTexts.saved}
            </Button>
      </ModalFooter>
    </Modal>
  }
  
  BackupCodesModal.propTypes = {
    show: PropTypes.bool,
    toggle: PropTypes.func,
    translatedTexts: PropTypes.object,
    backupCodes: PropTypes.array,
  }
  
  const DownloadIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color="#000000" fill="none" {...props}>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" />
      <path d="M12 7V12.5M10 11L11.2929 12.2929C11.6262 12.6262 11.7929 12.7929 12 12.7929C12.2071 12.7929 12.3738 12.6262 12.7071 12.2929L14 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.99023 16H14.9902" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

  const generateBackupCodesText = (email, codes) => {
    const formattedCodes = codes.map((code, index) => {
      const formattedCode = `${code.slice(0, 4)} ${code.slice(4, 8)}`;
      return `${(index + 1).toString().padStart(2, " ")}.   ${formattedCode}`;
    });
  
    const codesText = formattedCodes.join('\n');
    const date = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  
    return `CyberShield Backup Codes
(${email})
Keep these backup codes somewhere safe but accessible.
  
${codesText}
  
* You can only use each backup code once.
* These codes were generated on: ${date}.`;
  };

const downloadBackupCodes = (email, codes) => {
    const text = generateBackupCodesText(email, codes);
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `CyberShield_backup_codes_${email}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
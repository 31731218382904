import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getSiteUserByid, getSiteUserList, getUsersInvalidate, getuserbyid, sendEmail, updateApiKey, updateUser, validateAccountRequest } from '../../../store/apps/user/userSlice';
import { Alert, Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import LoaderSimple from '../../../layouts/loader/LoaderSimple';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { translateText } from '../../../store/apps/translateText/translateTextSlice';
import LoginAttemptsChart from './LoginAttemptsChart';
import { Axios } from '../../../config';
import RegistrationsChart from './RegistrationsChart';
import TwofaAttemptsChart from './TwofaAttemptsChart';
import AddedTwofaMethodsChart from './AddedTwofaMethodsChart';
import { socket } from '../../../socket';
import OnlineUsers from './OnlineUsers';
import TrafficChart from './TrafficChart';
import useTranslateTexts from '../../../Context/useTranslateTexts';

export default function UserManagement() {
  const [stats, setStats] = useState({})
  const [usersCount, setUsersCount] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState({});


  const texts = {
    userManagement: "Gestion des utilisateurs"
  }
  const translatedTexts = useTranslateTexts(texts);

  async function getStats(){
    try{
      const res = await Axios.get("/user/getStats")

      setStats(res.data)
      
    } catch (err){
      console.log(err)
    }
    
  }
  

  useEffect(()=>{
    getStats()
  },[])

  useEffect(() => {
    socket.emit("getUsersCount");
    socket.emit("getOnlineUsers")

    socket.on("usersCount", (count) => {
      setUsersCount(count);
    });

    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    })

    return () => {
      socket.off("usersCount");
      socket.off("onlineUsers");
    };
  }, []);


  
  
  return (
    <div>
      <BreadCrumbs />
          <h1>{translatedTexts.userManagement}</h1>
      <Row>
        <Col lg="12">
          <OnlineUsers usersCount={usersCount} onlineUsers={onlineUsers} />
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="d-flex">
          <LoginAttemptsChart stats={stats} />
        </Col>
        <Col lg="6" className="d-flex">
          <RegistrationsChart stats={stats} />
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="d-flex">
          <TwofaAttemptsChart stats={stats} />
        </Col>
        <Col lg="6" className="d-flex">
          <AddedTwofaMethodsChart stats={stats} />
        </Col>
      </Row>
      <Row>
          <TrafficChart />
      </Row>
    </div>
  );
}

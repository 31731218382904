import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

export const fetchNetmapData = createAsyncThunk(
  'netmap/fetchDataNet',
  async ({ siteId }) => {
    try {
      let url = `/network/networkscan`;
      if ( siteId) {
        // If the user is a Client, siteId is required to be added
        url += `?siteId=${siteId}`;
    }
      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  });
export const fetchVulnerability = createAsyncThunk(
  'netmap/fetchvulnerabilty',
  async ({ siteId }) => {
    try {
      let url = `/network/vulnerability`;
      if (siteId) {
        // If the user is a Client, siteId is required to be added
        url += `?siteId=${siteId}`;
      }
      const res = await Axios.get(url);
      return res.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  });

const  initialState= {
  ports: null,
  isSuccessPort: false,
  isSuccessVuln: false,
  isErrorPort: false,
  isErrorVuln: false,
  isLoadingPort: false,
  isLoadingVuln: false,
  error: null,
  vulnerability: null,
}


const networkScanSlice = createSlice({
  name: 'netmap',
  initialState,
  reducers: {
    clearNetworkData: () => initialState},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetmapData.pending, (state) => {
        state.isLoadingPort = true;
        state.isErrorPort = false;
        state.isSuccessPort = false;
      })
      .addCase(fetchNetmapData.fulfilled, (state, { payload }) => {
        state.isLoadingPort = false;
        state.isErrorPort = false;
        state.isSuccessPort = true;
        state.ports = payload;
      })
      .addCase(fetchNetmapData.rejected, (state, { payload }) => {
        state.isLoadingPort = false;
        state.isErrorPort = true;
        state.isSuccessPort = false;
        // state.error = error.message;
        state.ports = null;
      })
      .addCase(fetchVulnerability.pending, (state) => {
        state.isLoadingVuln = true;
        state.isSuccessVuln = false;
        state.isErrorVuln = false;
      })
      .addCase(fetchVulnerability.fulfilled, (state, { payload }) => {
        state.isLoadingVuln = false;
        state.isSuccessVuln = true;
        state.isErrorVuln = false;
        state.vulnerability = payload;
      })
      .addCase(fetchVulnerability.rejected, (state, { payload }) => {
        state.isLoadingVuln = false;
        state.isSuccessVuln = false;
        state.isErrorVuln = true;
        state.vulnerability = null;        // state.error = payload.error.message;
      })
     
    
  },
});
export const { clearNetworkData } = networkScanSlice.actions;

export default networkScanSlice.reducer;

// src/store/onboardingSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';
import welcomeLogo from "../../../assets/images/Onboarding/Welcome.svg";

// If you want to add a new context, you need to:
// 1. Add it to the steps in your onboarding logic.
// 2. Initialize it in the `progress` object.
// 3. Update the Mongoose Schema in "OnboardingProgress.js" (backend) to include the new context.

// If you want to add a new step, simply add it to `steps[context]` with the `target` specified, which can be an ID or class.

// Note: These steps and contexts are intended for testing purposes only and are not suitable for professional use in production.
// For production, create a well-crafted onboarding story that aligns with your website's features and the desired user journey.

// The workflow for the product tour is managed in `FullLayout.js` using conditional statements.

const initialState = {

    version: 1,
    isActive: false,
    isCompleted: false,
    fullCompleted: false,
    currentStep: 0,
    context: 'Welcome',
    modalContext: null,
    isOnboardingModalOpen: false,
    visitedComponents: [],
    onboardingEnabled: true,

    rating: 0,
    message: '',
    steps: {
        Welcome: [
            { component: 'Body', target: 'body', content: 'onboarding.welcome.greeting', disableBeacon: true, spotlightClicks: true ,placement: 'center'},
            { component: 'Header', target: '.topbar .language-drop', content: 'onboarding.welcome.languageSelector' },
            { component: '', target: '.sidebarBox', content: 'onboarding.welcome.sidebarIntro', placement: 'right', navigationPath: 'dashboard', spotlightClicks: true },
        ],
        QuickOverview: [
            { component: 'Dashboard', target: '.topcards', content: 'onboarding.quickOverview.keyMetrics', disableBeacon: true, spotlightClicks: true },
            { component: 'Dashboard', target: '.Vulnerabities-list', content: 'onboarding.quickOverview.vulnerabilitiesList' },
            { component: 'Dashboard', target: '.protection-statique', content: 'onboarding.quickOverview.protectionStatique' },
        ],
        SiteManagement: [
            { component: 'Sidebar', target: '.id4-2', content: 'onboarding.siteManagement.addWebsite', disableBeacon: true, spotlightClicks: true, navigationPath: 'options/sites-list', disableOverlay: true },
            { component: 'sites-list', target: '.add-website', content: 'onboarding.siteManagement.addWebsiteForm', spotlightClicks: true, disableBeacon: true, disableOverlay: false },
            // { component: 'Sidebar', target: '.id1-2', content: 'onboarding.siteManagement.siteInformation', navigationPath: 'site-information'},
        ],
        SecurityFeatures: [
            { component: 'Body', target: 'body', content: 'onboarding.securityFeatures.intro', disableBeacon: true,placement: 'center'},
            { component: 'Sidebar', target: '.id2-2', content: 'onboarding.securityFeatures.sqliIADetection', navigationPath: 'security-modules/sqli-detection-by-ai' },
            { component: 'Sidebar', target: '.id2-3', content: 'onboarding.securityFeatures.visiteursSuspects' },
            { component: 'Sidebar', target: '.id2-4', content: 'onboarding.securityFeatures.botDetection' },
            { component: 'Sidebar', target: '.id2-5', content: 'onboarding.securityFeatures.ipManagement' },
        ],
        Configuration: [
            { component: 'Sidebar', target: '.id1-3', content: 'onboarding.configuration.phpConfig', navigationPath: 'php-configuration', disableBeacon: true},
            { component: 'php-configuration', target: '.php-configurations', content: 'onboarding.configuration.phpConfigDetails' },
            // { component: 'Sidebar', target: '.id1-3', content: 'onboarding.configuration.wafSettings' },
        ],
        CyberThreatModules: [
            { component: 'Sidebar', target: '.id3-1', content: 'onboarding.CyberThreatModules.passwordCheck', disableBeacon: true },
            { component: 'Sidebar', target: '.id3-2', content: 'onboarding.CyberThreatModules.emailCheck', disableBeacon: true },
            { component: 'Sidebar', target: '.id3-3', content: 'onboarding.CyberThreatModules.serviceCheck' },
        ],
        IPManagement: [
            { component: 'Sidebar', target: '.id2-5', content: 'onboarding.ipManagement.blockedIPs' ,navigationPath: '/security-modules/ip-management', disableBeacon: true},
            { component: 'ip-managment', target: '.ip-managment', content: 'onboarding.ipManagement.whitelisting' },
        ],
        // AdvancedFeatures: [
        //     { component: 'Sidebar', target: '.id2-7', content: 'onboarding.advancedFeatures.apiAccess', disableBeacon: true },
        //     { component: 'Sidebar', target: '.id4-1', content: 'onboarding.advancedFeatures.backups' },
        // ],
        GlobalTour: [
            { component: 'Header', target: '.site-select', content: 'onboarding.globalTour.siteSelector', disableBeacon: true, navigationPath: 'dashboard', spotlightClicks: true },
            { component: 'Sidebar', target: '.id1-1', content: 'onboarding.globalTour.dashboardNavigation', disableBeacon: true},
            { component: 'Sidebar', target: '.id4-2', content: 'onboarding.globalTour.websiteManagement' },
            { component: 'Sidebar', target: '.id2-1', content: 'onboarding.globalTour.securityModules'},
            { component: 'Sidebar', target: '.id1-3', content: 'onboarding.globalTour.configuration'},
            { component: 'Sidebar', target: '.id2-6', content: 'onboarding.globalTour.monitoring'},
            { component: 'Sidebar', target: '.id2-5', content: 'onboarding.globalTour.ipManagement'},

        ],
        Conclusion: [
            { component: 'Header', target: '.help-center', content: 'onboarding.globalTour.helpResources', disableBeacon: true, navigationPath: 'dashboard', spotlightClicks: true },
            { component: 'Body', target: 'body', content: 'onboarding.conclusion.recap', disableBeacon: true, spotlightClicks: true ,placement: 'center'},

        ]
    },
    progress: {
        GlobalTour: 0,
        Welcome: 0,
        QuickOverview: 0,
        SiteManagement: 0,
        SecurityFeatures: 0,
        Configuration: 0,
        CyberThreatModules: 0,
        IPManagement: 0,
        AdvancedFeatures: 0,
        Conclusion: 0,
    },
// The context for modals may differ from the context for product tours.
// To add a new modal context, include it in the `modalContent` sections.
// Then, create a conditional statement for activation in `FullLayout.js`.

    showStartModal : true,
    modalContent: {
        Welcome: {
            title: "onboarding.modal.welcome.title",
            body: "onboarding.modal.welcome.body",
            primaryButton: "onboarding.modal.welcome.primaryButton",
            secondaryButton: "onboarding.modal.welcome.secondaryButton",
            image: welcomeLogo,
        },
        SiteManagement: {
            title: "onboarding.modal.siteManagement.title",
            body: "onboarding.modal.siteManagement.body",
            primaryButton: "onboarding.modal.siteManagement.primaryButton",
            secondaryButton: "onboarding.modal.siteManagement.secondaryButton",
            videoUrl: "https://www.youtube.com/embed/ZFLpfFnSko8?si=AeWXQdIHd1gqunnz"
        },
        Conclusion: {
            title: "onboarding.modal.conclusion.title",
            body: "onboarding.modal.conclusion.body",
            primaryButton: "onboarding.modal.conclusion.primaryButton",
            secondaryButton: "onboarding.modal.conclusion.secondaryButton",
        },
        QuickOverview: {
            title: "onboarding.modal.quickOverview.title",
            body: "onboarding.modal.quickOverview.body",
            secondaryButton: "onboarding.modal.quickOverview.secondaryButton",
            image: "complete",
            feedback: true,
        },

    },
    showChecklist : true,
    showVideoCard : true,
    showOnboardingBanner : true,
    contextTransitions: {
        'Welcome': 'SiteManagement',
        'SiteManagement': 'Conclusion',
        'Conclusion': 'QuickOverview',

    },
    initialOnboardingCompleted: false,
    isMobile: window.innerWidth < 1500,

};
export const dynamicStartThunk = createAsyncThunk(
    'onboarding/dynamicStart',
    async (_, { dispatch, getState }) => {
        dispatch(dynamicStart());
        dispatch(startOnboarding());
    }
)
export const saveProgress = createAsyncThunk(
    'onboarding/saveProgress',
    async (_, { getState }) => {
        const state = getState().onboarding;
        const progressData = {
            version: state.version,
            isCompleted: state.isCompleted,
            fullCompleted: state.fullCompleted,
            progress: state.progress,
            visitedComponents: state.visitedComponents,
            showChecklist: state.showChecklist,
            showVideoCard: state.showVideoCard,
            showOnboardingBanner: state. showOnboardingBanner,
            rating: state.rating,
            message: state.message,
            initialOnboardingCompleted: state.initialOnboardingCompleted,
            onboardingEnabled:state.onboardingEnabled,

        };

        const response = await Axios.post('/onboarding/progress', progressData);
        return response.data;
    }
);

export const fetchProgress = createAsyncThunk(
    'onboarding/fetchProgress',
    async (version = null) => {
        const url = '/onboarding/progress';
        const response = await Axios.get(url);
        return response.data;
    }
);


const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setInitialOnboardingCompleted: (state, action) => {
            state.initialOnboardingCompleted = action.payload;
        },
        
setOnboardingEnabled:(state,action) =>
  {
      state.onboardingEnabled = action.payload;
  },
        checkCompletion: (state) => {

            const Contexts = ['Welcome', 'SiteManagement','Conclusion']

            // Check if all steps in all contexts (except 'GlobalTour') are completed
            const allStepsCompleted = Contexts.every(context =>
                    state.progress[context] === state.steps[context].length - 1
                );

            // This is where isCompleted is set
            state.isCompleted = allStepsCompleted;

            // Check if all contexts (including GlobalTour) are completed for fullCompleted
            const allContexts = Object.keys(state.steps);
            const allContextsCompleted = allContexts.every(context =>
                state.progress[context] === state.steps[context].length - 1
            );


            if (!state.fullCompleted) {
                state.fullCompleted = allContextsCompleted;
            }
        },

        startOnboarding: (state) => {
            if ((state.progress[state.context] < state.steps[state.context].length -1) || (state.context=== 'GlobalTour')){
                state.isActive = true;
                if(state.progress[state.context] === state.steps[state.context].length -1)
                {
                    state.currentStep =0;
                }
                else {
                    state.currentStep = state.progress[state.context];}
                // console.log("onboarding slice started");
            }
            else
            {
                state.isActive = false;
            }
        },
        nextStep: (state) => {
            if (state.currentStep < state.steps[state.context].length - 1) {
                state.currentStep += 1;
                state.progress[state.context] = state.currentStep;
            } else {
                state.isActive = false;
                // state.progress[state.context] = state.steps[state.context].length;

            }
            onboardingSlice.caseReducers.checkCompletion(state);
        },
        prevStep: (state) => {
            if (state.currentStep > 0) {
                state.currentStep -= 1;
            } else {
                state.isActive = false;
            }
        },
        markComponentVisited: (state, action) => {
            if (!state.visitedComponents.includes(action.payload)) {
                state.visitedComponents.push(action.payload);
            }
        },
        endOnboarding: (state) => {
            state.isActive = false;
            state.currentStep=0;
            onboardingSlice.caseReducers.checkCompletion(state);
        },

        handleContextTransition: (state) => {
            const currentContext = state.context;
            const nextContext = state.contextTransitions[currentContext];

            if (nextContext) {
                if (state.progress[nextContext] <= state.steps[nextContext].length - 1) {
                    if (nextContext && state.steps[nextContext]) {
                        state.context = nextContext;
                    }
                    state.modalContext = nextContext;
                    state.showStartModal = true;
                } else {
                    // If the next context is already completed, recursively call this reducer
                    state.context = nextContext;
                    return onboardingSlice.caseReducers.handleContextTransition(state);
                }
            } else {
                // Handle the case when there's no next context (e.g., end of onboarding)
                console.log('Onboarding completed');
                state.showStartModal = false;
            }
            state.isActive = false;
        },

        setContext:(state,action) =>
        {

            state.context = action.payload;
        }
        ,
        setFeedbackRating: (state, action) => {
            state.rating = action.payload;
        },
        setFeedbackMessage: (state, action) => {
            state.message = action.payload;
        },
        setModalContext: (state, action) => {
            state.modalContext = action.payload;
        },
        setShowStartModal:(state,action) =>
        {
            state.showStartModal = action.payload;
        },
        setIsOnboardingModalOpen:(state,action)=>
        {
            state.isOnboardingModalOpen = action.payload;
        },

        setShowVideoCard:(state,action) =>
        {
            state.showVideoCard = action.payload;
        },
        setShowChecklist:(state,action) =>
        {
            state.showChecklist = action.payload;
        },
        setShowOnboardingBanner:(state,action) =>
        {
            state.showOnboardingBanner = action.payload;
        },

        // dynamicStart updates the onboarding context dynamically based on user progress.
        // Use this function to set the context to different onboarding sections (e.g., 'Onboarding', 'AddWebsite') depending on the user's current progress.
        // If you want to add a product tour context to dynamicOnboarding feature, update the conditions and context assignments .
        dynamicStart: (state) => {
            const contexts = Object.keys(state.steps);

            // Find the first incomplete context
            const nextIncompleteContext = contexts.find(context =>
                state.progress[context] < state.steps[context].length - 1
            );

            if (nextIncompleteContext) {
                state.context = nextIncompleteContext;
            } else {
                // If all contexts are complete, set to 'global' or handle as needed
                state.context = 'GlobalTour';
            }

            // Reset current step to the last incomplete step in the context
            if (state.context !== 'GlobalTour') {
                state.currentStep = state.progress[state.context];
            } else {
                state.currentStep = 0; // Or handle 'global' context as needed
            }
            onboardingSlice.caseReducers.checkCompletion(state);
        },
        resetOnboarding: (state) => {
            const allProgressZero = Object.values(state.progress).every(value => value === 0);

            if (!allProgressZero) {
                state.isActive = false;
                state.isCompleted = false;
                state.currentStep = 0;

                // Dynamically reset all progress values
                Object.keys(state.progress).forEach(key => {
                    state.progress[key] = 0;
                });

                state.visitedComponents = [];
                state.context = Object.keys(state.steps)[0] || 'Welcome'; // Set to first context or 'Welcome' if empty
                state.version += 1;
                onboardingSlice.caseReducers.checkCompletion(state);
            }
        },
        handleStepAction: (state, action) => {
            const { type, target } = action.payload;
            const currentStep = state.steps[state.currentStep];

            if (state.isActive) {
                if (currentStep.component === 'Sidebar') {
                    // For Sidebar, only progress if the clicked item matches the current step
                    if (true){
                        if (state.currentStep < state.steps.length - 1) {
                            state.currentStep += 1;
                        } else {
                            state.isActive = false;
                        }
                    }
                } else
                    // For non-Sidebar components, progress on 'next' action
                if (type === 'next') {
                    if (state.currentStep < state.steps.length - 1) {
                        state.currentStep += 1;
                    } else {
                        state.isActive = false;
                    }

                }
            }
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        handleMobileChange: (state) => {
            if (state.isMobile) {
                state.isActive = false;
                state.showStartModal = false;
                state.showChecklist = false;
                state.showOnboardingBanner = false;
            }
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(saveProgress.fulfilled, (state, action) => {
                // You can update the state here if needed
                console.log('Progress saved successfully');
            })
            .addCase(saveProgress.rejected, (state, action) => {
                console.error('Failed to save progress', action.error);
            })
            .addCase(fetchProgress.fulfilled, (state, action) => {
                const { isCompleted,onboardingEnabled, progress, context, visitedComponents, showChecklist,showVideoCard,version ,rating,message,  initialOnboardingCompleted} = action.payload;
                state.version = version;
                state.isCompleted = isCompleted;
                state.progress = progress;
                state.visitedComponents = visitedComponents;
                state.showChecklist=showChecklist;
                state.showVideoCard=showVideoCard;
                state.rating=rating;
                state.message = message;
                state.initialOnboardingCompleted = initialOnboardingCompleted;
                state.onboardingEnabled=onboardingEnabled;

            })
            .addCase(fetchProgress.rejected, (state, action) => {
                state.initialOnboardingCompleted = false;
                console.error('Failed to fetch progress', action.error);

            });

    },
});

export const { startOnboarding, prevStep,nextStep, markComponentVisited, endOnboarding, handleStepAction , setInitialOnboardingCompleted,setContext ,setShowStartModal,setShowChecklist,setShowVideoCard,dynamicStart,resetOnboarding,setShowOnboardingBanner,setModalContext,setFeedbackRating, setFeedbackMessage, setIsOnboardingModalOpen,handleContextTransition,setIsMobile, handleMobileChange,setOnboardingEnabled} = onboardingSlice.actions;
export default onboardingSlice.reducer;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { profileUser } from "../store/apps/user/userSlice";
import LoaderSimple from "./loader/LoaderSimple";
import { fetchProgress } from "../store/apps/onboarding/onboardingSlice";

const MainLayout = () => {
    const { users } = useSelector((state) => state.userReducer);
    const dispatch = useDispatch()

    
    useEffect(()=>{
      dispatch(profileUser());
    },[])

    useEffect(()=>{
      if (users){
      dispatch(fetchProgress());
      }
    },[users])
    
    // initially, users is undefined, so undefined means that we don't know if the user is logged in or not
    // if users is null, it means that the user is not logged in
    // if users is an object, it means that the user is logged in
    if (users === undefined) return <div className="d-flex align-items-center justify-content-center" style={{height: "100vh"}}><LoaderSimple /></div>
    
    
  return <>
    <Outlet />
  </>
};

export default MainLayout;

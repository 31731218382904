//useOnboarding.js
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import useTranslateTexts from '../../Context/useTranslateTexts';
import { useTranslation } from 'react-i18next';

import {
    startOnboarding,
    handleStepAction,
    nextStep,
    markComponentVisited,
    endOnboarding,
    prevStep,
    setContext,
    setShowStartModal,
    setShowChecklist,
    setShowVideoCard,
    dynamicStartThunk,
    saveProgress,
    fetchProgress,
    resetOnboarding,
    setShowOnboardingBanner,
    setModalContext,
    setFeedbackRating, setFeedbackMessage,
    setIsOnboardingModalOpen,
    handleContextTransition, setInitialOnboardingCompleted,    setIsMobile,
    handleMobileChange,
    setOnboardingEnabled,
} from '../../store/apps/onboarding/onboardingSlice';

const useOnboarding = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const {
        isActive,
        currentStep,
        context,
        steps,
        visitedComponents,
        modalContent,
        progress,
        showStartModal,
        showVideoCard,
        showChecklist,
        isCompleted,
        showOnboardingBanner,
        modalContext, // Add this line to get modalContext from the state
        rating,
        message,
        isOnboardingModalOpen,
        initialOnboardingCompleted,
        isMobile,
        onboardingEnabled
    } = useSelector(state => state.onboarding);
    const { users, isAuthAuth, site, sites } = useSelector((state) => state.userReducer);

    // Note: The translation functionality has not been tested due to the absence of the necessary environment variables in the .env file.
    const translateSteps = (stepsObj) => {
        const translatedSteps = {};
        Object.entries(stepsObj).forEach(([key, value]) => {
            translatedSteps[key] = value.map(step => ({
                ...step,
                content: t(step.content)
            }));
        });
        return translatedSteps;
    };

    const translatedSteps = translateSteps(steps);
    const translateModalContent = (modalContentObj) => {
        const translatedModalContent = {};
        Object.entries(modalContentObj).forEach(([key, value]) => {
            translatedModalContent[key] = {
                ...value,
                title: t(value.title),
                body: t(value.body),
                primaryButton: value.primaryButton ? t(value.primaryButton) : undefined,
                secondaryButton: value.secondaryButton ? t(value.secondaryButton) : undefined,
            };
        });
        return translatedModalContent;
    };

    const translatedModalContent = translateModalContent(modalContent);


    useEffect(() => {
        if (users && users?.userRole === 'Admin' || users?.userRole ==='Client') {
            dispatch(setShowStartModal(false));
            dispatch(setShowChecklist(false));
            dispatch(setShowVideoCard(false));
            dispatch(setShowOnboardingBanner(false));
            dispatch(setIsOnboardingModalOpen(false));
            dispatch(setInitialOnboardingCompleted(true));
        }
    }, [users, dispatch]);

    // console.log('Current context:', context);
    // console.log('Available step contexts:', Object.keys(steps));
    // console.log('Steps for current context:', steps[context]);

    // Determine which modal content to use
    const currentModalContent = modalContext && translatedModalContent[modalContext]
        ? translatedModalContent[modalContext]
        : translatedModalContent[context];


    return {
        progress,
        isActive,
        isCompleted,
        currentStep,
        context,
        modalContent: currentModalContent, // Use the determined modal content
        steps: translatedSteps[context],
        visitedComponents,
        showStartModal,
        showChecklist,
        showVideoCard,
        showOnboardingBanner,
        rating,
        message,
        isOnboardingModalOpen,
        initialOnboardingCompleted,
        isMobile,
        onboardingEnabled,
        setInitialOnboardingCompleted: (state) => dispatch(setInitialOnboardingCompleted(state)),
        start: () => {
            dispatch(startOnboarding());
            dispatch(setIsOnboardingModalOpen(false));
        },
        next: () => {
            dispatch(nextStep());
            dispatch(saveProgress());  // Save progress after moving to next step
        },
        handleContextTransition: () =>
        {
            dispatch(handleContextTransition());
        },
        markVisited: (component) => dispatch(markComponentVisited(component)),
        end: () => {
            dispatch(endOnboarding());
            dispatch(saveProgress());  // Save progress after ending onboarding
        },
        stepAction: () => dispatch(handleStepAction({ type: 'next' })),
        prev: () => dispatch(prevStep()),
        setContext: (context) => dispatch(setContext(context)),
        setModalContext: (modalContext) => dispatch(setModalContext(modalContext)),
        setShowStartModal: (state) => dispatch(setShowStartModal(state)),
        setIsOnboardingModalOpen: (state) => {
            dispatch(setIsOnboardingModalOpen(state));
            dispatch(saveProgress());
        },
        setOnboardingEnabled:(state)=>{
            dispatch(setOnboardingEnabled(state))
            dispatch(saveProgress())
        },
        setShowChecklist: (state) => {
            dispatch(setShowChecklist(state));
            dispatch(saveProgress());
        },
        setShowVideoCard: (state) => dispatch(setShowVideoCard(state)),
        setShowOnboardingBanner: (state) => dispatch(setShowOnboardingBanner(state)),
        dynamicStart: () =>
        {
            dispatch(dynamicStartThunk());
            dispatch(setIsOnboardingModalOpen(false))
        },
        fetchProgress: () =>{ dispatch(fetchProgress())},
        resetOnboarding: () => {dispatch(resetOnboarding())
            dispatch(saveProgress());},
        setFeedbackRating: (rating) =>{
            dispatch(setFeedbackRating(rating));
            dispatch(saveProgress());
        },
        setFeedbackMessage: (feedbackMessage) =>{
            dispatch(setFeedbackMessage(feedbackMessage));
            dispatch(saveProgress());
        },
        setIsMobile: (value) => dispatch(setIsMobile(value)),
        handleMobileChange: () => dispatch(handleMobileChange()),
    };
};

export default useOnboarding;
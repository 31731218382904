import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

function ConfirmModal({ show, toggle = ()=>{}, onConfirm = async ()=>{}, onCancel = ()=>{}, confirmButtonText = "Confirm", cancelButtonText = "Cancel", loading = false, children }) {
  return (
    <Modal isOpen={show} toggle={toggle} centered>
      <ModalBody className="d-flex justify-content-center align-items-center text-center flex-column">
        {children}
        <div className="position-relative d-flex justify-content-center align-items-center gap-2">
            <Button color="secondary" onClick={()=>{ onCancel(); toggle(); }}>
            {cancelButtonText}
            </Button>
            <Button color="danger" onClick={async ()=>{ await onConfirm(); toggle(); }}>
            {confirmButtonText}
            </Button>
            {
                loading ?
                <div className="position-absolute" style={{top: "50%", left: "calc(100% + 5px)", transform: "translateY(-50%)"}}>
                    <div className='loader-sm loader-black' ></div>
                </div>
                : ""
            }
        </div>
      </ModalBody>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default ConfirmModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

export const stats = createAsyncThunk('info/fetchStats', async ({ period, siteId, userRole }, { rejectWithValue }) => {
  try {
    // Construire l'URL en fonction du rôle de l'utilisateur
    let url = `statis/stats?period=${period}&userRole=${userRole}`;
    if (userRole === 'Client' && siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    } else if (userRole === 'Client' && !siteId) {
      // Reject the promise if the siteId is missing for a client
      return rejectWithValue('SiteId is required for client role.');
    }

    const res = await Axios.get(url);
    return res.data;
  } catch (error) {
    // Utilisez rejectWithValue pour renvoyer l'erreur
    return rejectWithValue(error.response.data);
  }
});
export const statsAdmin = createAsyncThunk('info/fetchStatsAdmin', async ({ period, siteId, userRole }, { rejectWithValue }) => {
  try {
    // Construire l'URL en fonction du rôle de l'utilisateur
    let url = `statis/statsAdmin?period=${period}&userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    // Utilisez rejectWithValue pour renvoyer l'erreur
    return rejectWithValue(error.response.data);
  }
});

const  initialState= {
  isSuccessDash: false,
  isErrorDash: false,
  isFetchingDash: false,
data:null,
isFetchingDashAdmin:false,
isSuccessDashAdmin:false,
isErrorDashAdmin:false,
dataAdmin:null,

}
  export const dashboardSlice = createSlice({
    name: 'stats',
    initialState,

    reducers: {
      clearDashboardData:  () => initialState},
    extraReducers: (builder) => {
      builder
        .addCase(stats.pending, (state) => {
          state.isFetchingDash = true;
          // state.count=0;
          state.isErrorDash = false;
          state.isSuccessDash = false;})
      
        .addCase(stats.fulfilled, (state, { payload }) => {
          state.isFetchingDash = false;
          state.data = payload;
        state.isSuccessDash=true;
        state.isErrorDash=false
          })
          .addCase(stats.rejected, (state, { payload }) => {
            state.isFetchingDash = false;
            state.isErrorDash = true;
            state.isSuccessDash = false;
          })
        .addCase(statsAdmin.pending, (state) => {
          state.isFetchingDashAdmin = true;
          // state.count=0;
          state.isErrorDashAdmin = false;
          state.isSuccessDashAdmin = false;})
      
        .addCase(statsAdmin.fulfilled, (state, { payload }) => {
          state.isFetchingDashAdmin = false;
          state.dataAdmin = payload;
        state.isSuccessDashAdmin=true;
        state.isErrorDashAdmin=false
          })
          .addCase(statsAdmin.rejected, (state, { payload }) => {
            state.isFetchingDashAdmin = false;
            state.isErrorDashAdmin = true;
            state.isSuccessDashAdmin = false;
          })
          
        }})
    
        export const { clearDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
import React, { useState } from 'react'
import Chart from 'react-apexcharts';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import useTranslateTexts from '../../../Context/useTranslateTexts';

export default function AddedTwofaMethodsChart({stats = {}}) {
    const labels = Object.keys(stats?.addedTwofaMethods || {})
    const series = Object.values(stats?.addedTwofaMethods || {})
    
    const chartOptions = {
        type: 'pie',
        height: "300px",
        options: {
            // plotOptions: {
            //     pie:{
    
            //         donut: {
            //             labels:{
            //                 show: true,
            //                 total:{
            //                     showAlways: true,
            //                     show: true
            //                 }
            //             }
            //         },
            //     }
            // },
            labels,
            // labels: Object.keys(steps),
            // colors: ['#ffb703', '#023047', '#fb8500', '#219ebc', '#8ecae6'],
            legend: {
                position: 'left',
                formatter: function(val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, val.toFixed(1) + '%']
                },  
            },
        },
        series
    };

    const texts = {
        addedTwofaMethods: "Méthodes d'authentification à deux facteurs ajoutées",
      }
      const translatedTexts = useTranslateTexts(texts);

  return (
    <Card className="p-3 w-100">
        <h4 className='m-0 fw-bold text-black'>{translatedTexts.addedTwofaMethods}</h4>
        <p className='fw-bold fs-4 m-0 text-center'>Total: {series?.reduce((acc, curr) => acc + curr, 0)}</p>
        <Chart {...chartOptions}  />
    </Card>
  )
}

AddedTwofaMethodsChart.propTypes = {
    stats: PropTypes.object,
}